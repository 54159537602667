import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import Hidden from '@material-ui/core/Hidden';
import Plus6PayWhatPaidyCanDo from 'src/components/Landing/Plus6Pay/Plus6PayWhatPaidyCanDo';
import Plus6PayTopBanner from 'src/components/Landing/Plus6Pay/Plus6PayTopBanner';
import Footer6Pay from 'src/components/Landing/Footer6Pay';
import UseInstallments from 'src/components/Landing/UseInstallments';
import VerifyIdentity from 'src/components/Landing/VerifyIdentity';

const SEOProps = {
  title: SITE_METADATA.plus_6Pay.title,
  description: SITE_METADATA.plus_6Pay.description,
};

const TITLE_IDENTITY = (
  <>
    無料アップグレードは、
    <Hidden smUp>
      <br />
    </Hidden>
    たったの５分で完了。
  </>
);

const Plus6Pay = () => {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Plus6PayTopBanner />
      <UseInstallments title="使い方はとってもかんたん！" />
      <VerifyIdentity title={TITLE_IDENTITY} />
      <Plus6PayWhatPaidyCanDo title="ペイディが選ばれる理由" />
      <Footer6Pay />
    </Layout>
  );
};

export default Plus6Pay;
